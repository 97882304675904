import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { formValidate } from '../../utilities/form.validation';
import { SigninServices } from './signin.services';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'forgot-password-component',
  templateUrl: `./forgot-password.component.html`,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription = new Subscription();
  public faEmail = faEnvelope as IconProp;
  public validate: boolean = false;

  public formGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.email,
    ]),
  });

  constructor(private signinServices: SigninServices) {}

  ngOnInit(): void {}

  onForgetPassword() {
    if (!formValidate(this.formGroup)) return;
    const rawValue = this.formGroup.getRawValue();

    this.signinServices.forgotPassword(rawValue).subscribe({
      next: (d) => {
        this.validate = true;
      },
      error: (err) => {
        this.validate = false;
        console.log(err);
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
