/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SigninComponent } from './signin.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SigninServices } from './signin.services';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from '../../app.service';
import { InputModule } from '../../components/input';
import { HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './register.component';
import { CustomValidatorModule } from '../../components/customValidator';
import { CheckboxModule } from '../../components/checkbox';
import { ForgotPasswordComponent } from './forgot-password.component';

export const COMPONENTS = [
  ForgotPasswordComponent,
  RegisterComponent,
  SigninComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    HttpClientModule,
    RouterModule,
    InputModule,
    NgOptimizedImage,
    CustomValidatorModule,
    CheckboxModule,
  ],
  exports: [...COMPONENTS],
  providers: [AppState, SigninServices],
})
export class SigninModule {}
