import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'src/app/services';
export const LoggerStateSignal = signal([]);

export type TLoggerQueryParams = { logger: string; print: string };
export type TMessageLog = { type: string; message: string };

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private onLogs = new BehaviorSubject(null);

  constructor(private localstorage: LocalStorageService) {}

  public get Logs() {
    return LoggerStateSignal();
  }

  public flush() {
    LoggerStateSignal.set([]);
  }

  public info(...args) {
    this.set('info', ...args);
  }

  public error(...args) {
    this.set('error', ...args);
  }

  public log(...args) {
    this.set('log', ...args);
  }

  private get loggerEnabled() {
    const logger = this.localstorage.get<TLoggerQueryParams>('logger')?.logger;
    return logger === 'true';
  }

  private set(type, ...args) {
    if (!this.loggerEnabled) return;
    args.forEach((log) =>
      this.onLogs.next({ type, message: `${JSON.stringify(log)}\n` }),
    );

    LoggerStateSignal.update((arr) => {
      return [
        ...arr,
        ...args.map((log) => ({ type, message: `${JSON.stringify(log)}` })),
      ];
    });
  }

  public onMessages(call) {
    return this.onLogs.subscribe(call);
  }
}
