import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICheckbox } from './checkbox.component';

@Component({
  selector:
    'checkbox-group[formControlName],checkbox-group[formControl],checkbox-group[ngModel]',
  styles: [
    `
      :host {
        position: relative;
      }

      ul {
        list-style: none;
        margin: 0;
      }

      li {
        margin: 5px 0;
      }
    `,
  ],
  templateUrl: `./checkbox.group.component.html`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxGroupComponent
  implements OnInit, OnChanges, ControlValueAccessor, AfterContentInit
{
  public _model: ICheckbox[] = [];
  @ViewChild('list', { static: true }) list: ElementRef;

  @Input() readonly: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() ready: EventEmitter<any> = new EventEmitter<any>();

  @Input() set ngValue(v: ICheckbox[]) {
    this._model = v;
  }

  constructor() {}

  ngOnInit(): void {}

  private cleanObject(arr) {
    const arrCopy = Array.isArray(arr) ? arr : [arr];

    arrCopy.forEach((obj) => {
      delete obj.HTMLElement;
      delete obj.indeterminate;
      delete obj.parent;
      delete obj.update;

      Object.keys(obj).forEach((k) => {
        if (k === 'children') obj[k] = this.cleanObject(obj[k]);
      });
    });

    return arrCopy;
  }

  onChange(e, checkbox) {
    const changed = checkbox;
    const ngModel = this.ngValue;
    const param = {
      ngModel,
      changed,
    };

    this.change.emit(param);
    this.onChangeCallback(param);
    this.onTouchedCallback(param);
  }

  ngAfterContentInit(): void {
    this.ready.emit(this.LayoutElement);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  get ngValue() {
    return this._model;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (obj && obj !== this.ngValue) {
      this.ngValue = obj;
    }
  }

  private onTouchedCallback = (v: any) => {};

  private onChangeCallback = (v: any) => {};

  get LayoutElement(): HTMLElement {
    return this.list.nativeElement as HTMLElement;
  }
}
