import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'layout',
  styleUrls: ['./layout.component.scss'],
  templateUrl: `./layout.component.html`,
})
export class LayoutComponent implements OnDestroy {
  @ViewChild('maincontainer', { static: true }) maincontainer: ElementRef;

  private subscriptions: Subscription = new Subscription();
  public file;
  public workbook;

  constructor() {}

  onSignIn(e) {}

  get MainElement(): HTMLElement {
    return this.maincontainer.nativeElement;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
