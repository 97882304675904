import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

//https://bootstrap-menu.com/
@Directive({
  selector: '[dropdownItem]',
})
export class DropdownItemDirective implements OnInit {
  constructor() {}

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    e.stopPropagation();
  }

  ngOnInit(): void {}
}
