import { Component, Input } from '@angular/core';
import { faCircle, faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FormControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'custom-validator',
  template: `
    <fa-icon
      [icon]="controller.valid && controller.dirty ? faCheckCircle : faCircle"
      [ngClass]="{
        'text-success': controller.valid && controller.dirty,
        'text-danger': controller.invalid && controller.dirty
      }"
    ></fa-icon>
  `,
  styles: [
    `
      :host {
        font-size: 14px;
      }
    `,
  ],
})
export class CustomValidatorComponent {
  @Input('controller') controller: FormControl;

  public faCircle = faCircle as IconProp;
  public faCheckCircle = faCheckCircle as IconProp;
}
