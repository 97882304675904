<header class="pb-3 w-100">
  <div class="row no-gutters g-0 d-flex flex-wrap justify-content-between">
    <div class="col-4 mb-2">
      <brand-title></brand-title>
    </div>
    <div class="col-8">
      <div class="d-flex w-100 flex-row justify-content-end">
        <div
          class="w-100 d-none d-md-block"
          *ngIf="showSearch && !showNavigation"
          [ngSwitch]="customSearch"
        >
          <ng-content *ngSwitchCase="true"></ng-content>
        </div>

        <div
          class="pb-2 pt-3"
          *ngIf="showSignin"
          [ngClass]="{ 'width-100px': !showNavigation }"
        >
          <div class="d-flex justify-content-end">
            <div class="" [ngSwitch]="isAuthenticated">
              <button
                type="button"
                class="btn-signin ripple btn btn-primary btn-sm"
                *ngSwitchDefault
                (click)="onSignIn($event)"
              >
                {{ "signIn" | translate }}
              </button>
              <app-profile-bar
                *ngSwitchCase="true"
                (onSignOut)="onSignOut($event)"
                [ngClass]="{
                  'd-none d-md-block': !isAuthenticated && !showSearch
                }"
              ></app-profile-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-md-none" *ngIf="showSearch" [ngSwitch]="customSearch">
      <ng-content *ngSwitchCase="true"></ng-content>
    </div>
  </div>
</header>
