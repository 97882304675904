import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { APPSTATE } from '../../../constants/app-state.constants';
import { Subscription } from 'rxjs';
import { AppState } from '../../../app.service';

@Component({
  selector: 'page-template',
  styleUrls: ['./page.component.scss'],
  template: ` <ng-content></ng-content> `,
})
export class PageTemplateComponent implements OnChanges, OnDestroy {
  @Input() brandHeader: string = '';
  public username: string = '';

  private subscriptions: Subscription = new Subscription();

  constructor(private appState: AppState) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.appState.set(APPSTATE.APPNAME, this.brandHeader);
    this.username = this.appState.get(APPSTATE.USERNAME);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
