import {
  AfterViewInit,
  Component,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ViewContainerRefComponent } from 'src/app/components/resolveNgContent.service';

@Component({
  selector: '[app-root]',
  template: `
    <router-outlet></router-outlet>
    <div debugger></div>
  `,
  host: {
    class: 'h-100',
  },
})
export class AppComponent implements AfterViewInit {
  constructor(
    viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    ViewContainerRefComponent.Instance.setViewContainerRef(viewContainerRef);
  }

  ngAfterViewInit(): void {
    // if (!isPlatformBrowser(this.platformId)) return;
    this.renderer.addClass(this.document.body, 'is-document-loaded');
  }
}
