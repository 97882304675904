import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AbstractControlDirective,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

export interface ICheckbox {
  label: string;
  checked: boolean;
  children: ICheckbox[];
  HTMLElement?: HTMLElement;
  parent?: ICheckbox;
  indeterminate?: boolean;
  update?: EventEmitter<ICheckbox>;
}

class ObjectModel implements ICheckbox {
  label: string = '';
  checked: boolean = false;
  children: ICheckbox[] = [];
  update: EventEmitter<ICheckbox> = new EventEmitter();

  constructor(value) {
    this.checked = value;
  }
}

@Component({
  selector: 'checkbox[formControlName],checkbox[formControl],checkbox[ngModel]',
  styleUrls: ['./checkbox.component.scss'],
  templateUrl: `./checkbox.component.html`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent
  implements OnInit, OnChanges, ControlValueAccessor, AfterContentInit
{
  @Input() readonly: boolean = false;
  @Input() bgCheckedColor: string = '#52cf71';

  @HostBinding('class.indeterminate') public $$indeterminate: boolean = false;

  @ViewChild('inputModel', { static: true }) inputModel: ElementRef;
  @ViewChild('ctrl', { static: true }) ctrl: AbstractControlDirective;

  @Input() id: string = this.uniqueID;
  @Input() formSubmit: boolean = false;
  @Input() required: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() name: string = '';

  @Input() ngModelObject: ICheckbox = new ObjectModel(this.ngValue);
  @Input() ngParentObject: ICheckbox;

  @Output() ready: EventEmitter<any> = new EventEmitter<any>();

  public _model: boolean = false;
  public _disabled: boolean;
  public invalid: boolean = true;
  public valid: boolean = false;
  public dirty: boolean = false;

  public ngModelCtrl: AbstractControl;

  constructor(
    private _ngZone: NgZone,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  get uniqueID() {
    const S4 = () =>
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }

  onClicked(e) {
    e.stopPropagation();
  }

  ngOnInit(): void {
    this.buildNgModelObject();
  }

  set ngValue(v: boolean) {
    this._model = v;
  }

  set disabled(v: boolean) {
    this._disabled = v;
  }

  set Indeterminate(v: boolean) {
    this.$$indeterminate = v;
  }

  get Indeterminate() {
    return this.$$indeterminate;
  }

  private updateNgModelObj() {
    this.ngModelObject.checked = this.ngValue;
    this.ngModelObject.indeterminate = this.Indeterminate;
  }

  private buildNgModelObject() {
    if (!this.ngModelObject) return;

    this.ngModelObject.HTMLElement = this.InputElement;

    const fn = this.ngModelObject['update'];
    if (typeof fn !== 'function')
      Object.defineProperty(this.ngModelObject, 'update', {
        value: new EventEmitter<any>(),
        writable: false,
        configurable: true,
      });

    this.ngModelObject.update.subscribe((d) => {
      this.Indeterminate = d.indeterminate;
      this.ngValue = d.checked;

      this.updateNgModelObj();
      this.checkSiblings();
    });

    if (
      this.ngParentObject &&
      this.ngParentObject.HTMLElement !== this.InputElement
    )
      this.ngModelObject.parent = this.ngParentObject;
  }

  ngAfterContentInit(): void {
    this.ready.emit(this.LayoutElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.formSubmit &&
      typeof changes.formSubmit.previousValue === 'undefined'
    )
      return;
    this.dirty = this.formSubmit ? true : this.ctrl.dirty;
    this.setStatus(this.InputElement, this.indeterminate);
  }

  modelChanged(e) {
    this.Indeterminate = false;
    this.ngValue = e;

    if (this.ngModelObject && this.ngModelObject.children)
      this.updateChildren(this.ngModelObject.children, e);
    this.setStatus(this.InputElement, this.Indeterminate, e);

    this.updateNgModelObj();
    this.checkSiblings();

    this.onChangeCallback(this.ngValue);
    this.onTouchedCallback(this.ngValue);
  }

  private updateChildren(children, status) {
    if (children && children.length)
      children.map((c) => {
        if (c.hasOwnProperty('disabled') && c.disabled) return;

        c.checked = status;
        c.indeterminate = false;
        this.setStatus(c.HTMLElement, c.indeterminate, c.checked);
        if (c.children) this.updateChildren(c.children, status);
      });
  }

  private setStatus(
    element: HTMLElement = this.InputElement,
    indeterminate: boolean = this.Indeterminate,
    checked: boolean = this.ngValue,
  ) {
    this.renderer.setProperty(element, 'indeterminate', indeterminate);
    this.renderer.setProperty(element, 'checked', checked);

    this.Indeterminate = indeterminate;

    // console.log('==============setStatus======================');
    // console.log('InputElement', element);
    // console.log('indeterminate', indeterminate);
    // console.log('checked', checked);
  }

  private checkSiblings(parent = this.ngParentObject) {
    const siblings =
      parent && parent.children && parent.children.length
        ? parent.children.filter((c) => c.HTMLElement !== this.LayoutElement)
        : [];

    const checked = siblings.filter((s: any) => s.checked);
    const indeterminate = siblings.filter((s) => s.indeterminate);
    const all = checked.length === siblings.length;

    // console.log('==============checkSiblings======================');
    // console.log('siblings', siblings);
    // console.log('ngValue', this.ngValue);
    // console.log('checked', checked);
    // console.log('indeterminate', indeterminate);
    // console.log('all', all);

    this.setParent(
      parent,
      checked.length ? !all : indeterminate.length ? true : false,
      all,
    );
  }

  private setParent(
    parent,
    indeterminate: boolean = true,
    checked: boolean = false,
  ) {
    if (!parent || parent.HTMLElement === this.InputElement) return;

    parent.indeterminate = indeterminate;
    parent.checked = checked;

    // console.log('==============setParent======================');
    // console.log('InputElement', parent.HTMLElement);
    // console.log('indeterminate', parent.indeterminate);
    // console.log('checked', parent.checked);

    this.setStatus(parent.HTMLElement, parent.indeterminate, parent.checked);
    if (parent.HTMLElement !== this.InputElement && parent.update)
      parent.update.emit(parent);
  }

  get ngValue() {
    return this._model;
  }

  get disabled() {
    return this._disabled;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (obj !== this.ngValue) {
      this.ngValue = !!obj;
      this._ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.updateNgModelObj();
          this.checkSiblings();
        });
      });
    }
  }

  validate(ctrl: AbstractControl): ValidationErrors | null {
    this.ngModelCtrl = ctrl;

    const errors = <ValidationErrors>ctrl?.errors;
    this.required = errors?.required ?? false;

    return null;
  }

  private onTouchedCallback = (v: any) => {};

  private onChangeCallback = (v: any) => {};

  get InputElement(): HTMLElement {
    return this.inputModel.nativeElement as Element as HTMLElement;
  }

  get LayoutElement(): HTMLElement {
    return this.elementRef.nativeElement as Element as HTMLElement;
  }
}
