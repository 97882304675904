import { Injectable } from '@angular/core';
import { AppState } from '../app.service';
import { formatBytes } from '../utilities/formatBytes';
import { IGetHDDStats } from '../models/IGetHDDStats';

@Injectable()
export class StatsService {
  constructor(private appState: AppState) {}

  getHDDStats(): IGetHDDStats {
    const { used_storage, format_used_storage } = this.appState.get(
      'account_details',
      { used_storage: null, format_used_storage: null },
    );
    const { account_limit } = this.appState.get('user');
    const total_percent = (used_storage / account_limit) * 100;

    return {
      total_percent,
      format_used_storage,
      format_total_storage:
        account_limit > -1 ? formatBytes(account_limit) : 'Unlimited',
    };
  }
}
