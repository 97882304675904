<label
  *ngIf="label"
  [innerHTML]="label"
  [attr.for]="id"
  class="form-label label no-pointer-events"
  [class.has-value]="_model !== null && _model !== undefined"
></label>
<div class="pos-relative">
  <div
    class="d-inline-flex align-items-center mb-1 w-100"
    [attr.id]="id"
    [class.has-value]="_model !== null && _model !== undefined"
    [class.is-invalid]="isInvalid(ctrl)"
    #inputGroup
  >
    <i
      class="text-125 ml-15 pos-abs"
      [ngClass]="{
        'text-danger': isInvalid(ctrl),
        'text-blue': !isInvalid(ctrl)
      }"
      #prefixWrapper
    >
      <ng-content select="prefix"></ng-content>
    </i>

    <input
      class="form-control text-dark-m1 brc-default-m3 radius-0"
      [class.text-dark-m1]="!readonly"
      [class.brc-h-warning-m1]="!readonly"
      [class.bgc-h-warning-l3]="!readonly"
      [class.has-suffix]="hasAppend"
      [class.has-prefix]="hasPrepend"
      [type]="type"
      [attr.name]="name"
      [attr.id]="id"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabledControl]="disabled || readonly"
      [autocomplete]="autocomplete"
      [attr.autocorrect]="autocorrect"
      [attr.x-autocompletetype]="autocomplete"
      [(ngModel)]="_model"
      (ngModelChange)="modelChanged($event)"
      [required]="required"
      [attr.maxlength]="maxlength"
      [attr.minlength]="minlength"
      [attr.min]="min"
      [attr.max]="max"
      [class.has-value]="_model !== null && _model !== undefined"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      [class.is-invalid]="ctrl && ctrl.invalid && ctrl.dirty"
    />
    <i
      class="text-green text-125 ml-n475"
      [ngClass]="{
        'text-danger': isInvalid(ctrl),
        'text-blue': !isInvalid(ctrl)
      }"
      #suffixWrapper
    >
      <ng-content select="suffix"></ng-content>
      <ng-content select="custom-validator"></ng-content>
    </i>
  </div>
</div>
<ng-content select="small"></ng-content>
