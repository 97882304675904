import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { get } from 'lodash';
import { faBars, faSearch } from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EAppRoutes } from '../../models/EAppRoutes';

class IsAuthenticatedNotification {
  private static _instance: IsAuthenticatedNotification;

  private $emitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  subscribe(next?: (e: boolean) => void, error?: (e: any) => void) {
    this.$emitter.subscribe(
      (e) => next(e),
      (e) => error(e),
    );
  }

  next(d?: boolean) {
    return this.$emitter.emit(d);
  }
}

export const AuthenticatedSignInNotification: IsAuthenticatedNotification =
  IsAuthenticatedNotification.Instance;

@Component({
  selector: 'eq-header',
  styleUrls: ['../../containers/public/theme.scss', './header.component.scss'],
  templateUrl: `./header.component.html`,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;
  @Input() showSearch: boolean = true;
  @Input() showNavigation: boolean = false;
  @Input() isAuthenticated: boolean = false;
  @Input() showSignin: boolean = true;
  @Input() customSearch: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  private subscriptions: Subscription = new Subscription();
  public searchIcon = faSearch;
  public faBars = faBars;
  public queryType: any;
  public queryParams = {};
  public headerRedirectURL = ['/'];
  public query = '';
  public queryTypes = [
    {
      name: this.translate.instant('match'),
      value: 'eq',
    },
    {
      name: this.translate.instant('contains'),
      value: 'in',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {}

  onSearchChange(e) {
    this.change.emit(e);
  }

  setQueryType(type = 'eq') {
    this.queryType = this.queryTypes.find((a) => a.value === type);
  }

  ngOnInit(): void {
    this.queryType = this.queryTypes.find((a) => a.value === 'eq');
    this.subscriptions.add(
      this.route.data.subscribe((data) => {
        const { AlreadyLoggedInResolver = this.isAuthenticated } = data;
        this.isAuthenticated = !!AlreadyLoggedInResolver;

        if (this.isAuthenticated) {
          this.headerRedirectURL = [
            EAppRoutes.ROOT,
            EAppRoutes.DASHBOARD,
            EAppRoutes.DRIVE,
          ];
        }
      }),
    );
    this.subscriptions.add(
      this.route.queryParams.subscribe((d) => {
        this.queryParams = d;
        this.query = get(d, 'q', '');
        this.setQueryType(get(d, 'c', 'eq'));
      }),
    );

    AuthenticatedSignInNotification.subscribe((d) => {
      this.isAuthenticated = d;
    });
  }

  onSignOut(e) {}

  onSignIn(e) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {}
}
