import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faUserCircle, faBell } from '@fortawesome/pro-light-svg-icons';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../app.service';
import { EAppFullRoutes, EAppRoutes } from '../../models';
import { SigninServices } from 'src/app/containers/signin';

@Component({
  selector: 'app-profile-bar',
  styleUrls: ['./profilebar.component.scss'],
  templateUrl: `./profilebar.component.html`,
})
export class ProfileBarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  @Output() onSignOut: EventEmitter<any> = new EventEmitter<any>();
  public faUserCircle = faUserCircle;
  public username;
  public faBell = faBell;

  public mailCount = 0;
  public mailBalloon;
  public routerLinkPath = `${EAppRoutes.DASHBOARD}/`;
  public routerLinkRoutes = EAppFullRoutes;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private appState: AppState,
    private router: Router,
    private signinServices: SigninServices,
  ) {}

  signOut(e) {
    this.signinServices.flush().subscribe((d) => {
      this.router.navigate(['/']).then();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setUsername(username) {
    this.username = username;
    if (username) this.routerLinkPath = ['/u', username].join('/');
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data.subscribe(({ AlreadyLoggedInResolver }) => {
        this.setUsername(
          this.appState.get('user.username', AlreadyLoggedInResolver),
        );
      }),
    );

    this.mailBalloon = this.translate.instant('youGotMail', {
      number: this.mailCount,
    });
  }
}
