/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTemplateComponent } from './page.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PageTemplateComponent],
  imports: [CommonModule, TranslateModule, FontAwesomeModule, RouterModule],
  exports: [PageTemplateComponent],
})
export class PageTemplateModule {}
