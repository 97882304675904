import { Inject, Injectable, InjectionToken } from '@angular/core';

import { Observable, Subject } from 'rxjs';

export const USE_ROUTER_DEFINITIONS = new InjectionToken<string>(
  'USE_ROUTER_DEFINITIONS',
);
export const APP_DRAWER_LIST = new InjectionToken<string>('APP_DRAWER_LIST');
export const APP_HEADER_CONTROL_DROPDOWN = new InjectionToken<string>(
  'APP_HEADER_CONTROL_DROPDOWN',
);

export interface IAPPCONFIG {
  active?: boolean;
  path: string;
  translate: string;
  faIcon?: any;
  disabled?: boolean;
  permission: string[];
}

@Injectable()
export class LayoutServices {
  constructor(
    @Inject(USE_ROUTER_DEFINITIONS) private routerDefinitions: any[] = [],
    @Inject(APP_DRAWER_LIST) private appDrawerList: any,
  ) {}

  getAppList(): Observable<IAPPCONFIG[]> {
    return new Observable((obs) => {
      const type = typeof this.appDrawerList;
      if (type === 'function') {
        const startAsync: any = async (callback) =>
          await this.appDrawerList().then((d) => callback(d));
        startAsync((text) => obs.next(text));
      } else obs.next(this.appDrawerList);
    });
  }
}
