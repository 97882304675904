/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  APP_DRAWER_LIST,
  LayoutServices,
  USE_ROUTER_DEFINITIONS,
} from './layout.services';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from '../app.service';
import { PageTemplateModule } from './template/page';
import { FormsModule } from '@angular/forms';
import { StatsService } from './stats.service';
import { HeaderModule } from './header/header.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    HeaderModule,
    PageTemplateModule,
  ],
  exports: [
    LayoutComponent,
    FontAwesomeModule,
    PageTemplateModule,
    HeaderModule,
  ],
  providers: [
    AppState,
    LayoutServices,
    StatsService,
    { provide: USE_ROUTER_DEFINITIONS, useValue: [] },
    { provide: APP_DRAWER_LIST, useValue: [] },
  ],
})
export class LayoutModule {}
