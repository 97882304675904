<ng-container *ngIf="loading; else signin">
  <ng-container *ngTemplateOutlet="loader"></ng-container>
</ng-container>

<ng-template #loader>
  <div class="spinner-container main-container container bgc-transparent">
    <div class="main-content minh-100 justify-content-center">
      <div class="p-2 p-md-4">
        <div
          *ngIf="true"
          class="loading-ctl pos-absolute pos-left d-flex justify-content-center align-items-center w-100"
        >
          <fa-icon
            [icon]="spinnerIcon"
            animation="spin"
            size="4x"
            class="text-orange"
          ></fa-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #signin>
  <div class="main-container container bgc-transparent">
    <div class="main-content minh-100 justify-content-center">
      <div class="p-2 p-md-4">
        <div class="row" id="row-1">
          <div
            class="col-12 col-xl-10 offset-xl-1 bgc-white shadow radius-1 overflow-hidden"
          >
            <div class="row" id="row-2">
              <div
                id="id-col-intro"
                class="col-lg-5 d-none d-lg-flex border-r-1 brc-default-l3 px-0"
              >
                <div class="minw-100 h-100">
                  <div class="carousel-item active minw-100 h-100">
                    <!-- default carousel section that you see when you open login page -->
                    <div
                      [ngStyle]="{
                        'background-image': 'url(/assets/image/login-bg-1.svg)'
                      }"
                      class="px-3 bgc-blue-l4 d-flex flex-column align-items-center justify-content-center"
                    >
                      <a class="mt-5 mb-2" href="/">
                        <i class="fa fa-leaf text-success-m2 fa-3x"></i>
                      </a>

                      <h2 class="text-primary-d1">
                        <span
                          class="text-80 text-dark-l1"
                          [innerHtml]="'appName' | translate"
                        ></span>
                      </h2>

                      <div class="mt-5 mx-4 text-dark-tp3 text-center">
                        <span
                          class="text-120"
                          [innerHTML]="'appEntrySummary' | translate"
                        >
                        </span>
                        <hr class="mb-1 brc-black-tp10" />
                      </div>

                      <div class="mt-auto mb-4">
                        <fa-icon
                          [icon]="faLeaf"
                          class="text-success-l3 me-1 text-110"
                        ></fa-icon>
                        <span [innerHTML]="footer" class="text-dark-tp2"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="id-col-main"
                class="col-12 col-lg-7 py-lg-5 bgc-white px-0"
              >
                <router-outlet></router-outlet>
              </div>
            </div>
            <!-- /.row -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="d-lg-none my-3 text-white-tp1 text-center">
          <fa-icon
            [icon]="faLeaf"
            class="text-success-l3 me-1 text-110"
          ></fa-icon>
          <span [innerHTML]="footer"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
