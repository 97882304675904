import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerComponent } from './logger.component';
import { LoggerService } from './logger.service';
import { LoggerInitializerProvider } from './logger.initializer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalStorageService } from 'src/app/services';

@NgModule({
  declarations: [LoggerComponent],
  imports: [CommonModule, FontAwesomeModule],
  providers: [...LoggerInitializerProvider, LocalStorageService, LoggerService],
  exports: [LoggerComponent],
})
export class LoggerModule {}
