import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { faEnvelope, faLock } from '@fortawesome/pro-light-svg-icons';
import { formValidate, markAsDirty } from '../../utilities/form.validation';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EAppFullRoutes } from '../../models';
import { SigninServices } from './signin.services';

@Component({
  selector: '[signin-component]',
  styleUrls: ['./signin.component.scss'],
  templateUrl: `./signin.component.html`,
})
export class SigninComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription = new Subscription();
  public redirect: boolean = true;
  public registered: boolean = false;
  public showPassword: boolean = false;
  public onAuthenticated: EventEmitter<any> = new EventEmitter<any>();
  public faLock = faLock as IconProp;
  public faEmail = faEnvelope as IconProp;
  public callback: () => Observable<any>;
  public queryParams = {};

  public formGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.email,
    ]),
    password: new FormControl('', [Validators.required]),
  });

  public email: string;
  public password: string;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private signinServices: SigninServices,
  ) {}

  forgotPassword() {
    const email = this.formGroup.controls['email'].value;
  }

  signIn() {
    if (!formValidate(this.formGroup)) return;
    const rawValue = this.formGroup.getRawValue();

    this.signinServices.authenticate(rawValue).subscribe({
      next: (user) => {
        this.onAuthenticated.emit(user);
        let command = [];
        const navigationExtras: NavigationExtras = {
          replaceUrl: true,
        };

        if (!this.callback) {
          command = [...EAppFullRoutes.LANDING];
        }

        if (this.redirect)
          this.router.navigate(command, navigationExtras).then();
      },
      error: (err) => {
        this.formGroup.reset();
        Object.keys(this.formGroup.controls).forEach((k) =>
          markAsDirty(this.formGroup.controls[k]),
        );
      },
      complete: () => {},
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.queryParams.subscribe((d) => {
        this.queryParams = d;
      }),
    );

    if (this.email && this.password) {
      this.formGroup.controls['email'].setValue(this.email);
      this.formGroup.controls['password'].setValue(this.password);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
