<div class="body-container">
  <eq-header>
    <div
      class="justify-content-start flex-row d-flex align-content-center align-items-center w-100 justify-content-between"
    >
      <div class=""></div>
      <div class="grouped">
        <button
          type="button"
          (click)="onSignIn($event)"
          class="ripple btn btn-primary rounded-pill text-capitalize"
        >
          {{ "signin" | translate }}
        </button>
      </div>
    </div>
  </eq-header>
  <router-outlet></router-outlet>
</div>
