/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ProfileBarComponent } from './profilebar.component';
import { FormsModule } from '@angular/forms';
import { EqCommonDirectivesModule } from '../../directives/eq.common.directives.module';
import { HttpClientModule } from '@angular/common/http';
import { SigninServices } from 'src/app/containers/signin';

@NgModule({
  declarations: [ProfileBarComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
    RouterModule,
    EqCommonDirectivesModule,
  ],
  providers: [SigninServices],
  exports: [ProfileBarComponent],
})
export class ProfileBarModule {}
