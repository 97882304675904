export interface ISettingsPrivacyOpt {
  optIndex: number;
  value: boolean | null;
}

export interface IPrivacySetting {
  followNotify: boolean;
  likesNotify: boolean;
  privateAccount: boolean;
  settingsPrivacyOpts?: [ISettingsPrivacyOpt];
}
