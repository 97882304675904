import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { faHdd } from '@fortawesome/pro-light-svg-icons';
import { get } from 'lodash';

@Component({
  selector: 'no-items-overlay',
  styleUrls: ['./overlayNoRowsTemplate.component.scss'],
  templateUrl: './overlayNoRowsTemplate.component.html',
})
export class OverlayNoRowsTemplateComponent
  implements INoRowsOverlayAngularComp
{
  public faIcon: any = faHdd;
  public faIconClass = 'text-light';
  public translateString = '';
  public showSubContext: boolean = true;

  agInit(params) {
    this.translateString = params['translateString'];
    this.showSubContext = get(params, 'showSubContext', this.showSubContext);
    if (params['faIcon']) this.faIcon = params['faIcon'];
    this.faIconClass = params['faIconClass'] || 'text-light';
  }
}
