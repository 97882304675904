import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  flush() {
    try {
      localStorage.clear();
    } catch (e) {}
  }

  get<T>(key: string, fallback?: T): T {
    let stored = localStorage.getItem(key) ?? '';
    try {
      stored = JSON.parse(stored);
    } catch (e) {}

    return <T>(<unknown>(stored ?? fallback));
  }

  set(key: string, value: string | object) {
    value = JSON.stringify(value);
    try {
      localStorage.setItem(key, value);
    } catch (e) {}

    return value;
  }

  remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {}
  }
}
