import {
  Inject,
  Injectable,
  PLATFORM_ID,
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { catchError, filter, map } from 'rxjs/operators';
import { Observable, take, of, throwError, timer, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URL_ENDPOINTS } from '../models';
import { WorkerTask } from '../components/workerClients/services/worker.task';
import { BackgroundTaskMessage } from '../components/workerClients/models/background-task.model';
import { v4 as uuidv4 } from 'uuid';
import { omitBy, isNil } from 'lodash';
import IQueryBuilderOptions, {
  IOperation,
} from 'gql-query-builder/build/IQueryBuilderOptions';
import VariableOptions from 'gql-query-builder/build/VariableOptions';
import { query, mutation } from 'gql-query-builder';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LoggerService } from '../components/logger/logger.service';

export interface IGraphQLService {
  query: string;
  name: string;
  variables?: VariableOptions;
}

type GraphQLErrorExtensions = {
  code: string;
  keys: string[];
};

export type GraphQLErrorHandlerResponse = {
  message: string;
  locations: Record<string, any>[];
  extensions: GraphQLErrorExtensions[];
};

export type IQueryBuilder = IQueryBuilderOptions & IGraphQLService;

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    private debuggerService: LoggerService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
  ) {}

  public query<T>({
    operation,
    fields,
    variables,
  }: IQueryBuilderOptions): Observable<T> {
    return this.letsMaskRequest({
      ...query({ operation, fields, variables }, null, {
        operationName: operation,
      }),
      name: operation as string,
    });
  }

  private letsMaskRequest({ query, variables, name }: IGraphQLService) {
    const body = { query, variables };
    const options = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (isPlatformBrowser(this.platformId)) {
      const task = this.buildTask(
        URL_ENDPOINTS.GRAPHQL,
        JSON.stringify(body),
        options,
      );

      const logger = new BehaviorSubject(task);
      const logs = [];
      logger.subscribe({
        complete: () => {
          this.debuggerService.info(task);
          logs.forEach((log) => this.debuggerService.log(log));
        },
      });
      return this.callWorkerTask(task).pipe(
        filter(({ taskStatus }) => taskStatus === 'TERMINATED'),
        map(({ payload }: any) => {
          const { data, errors } = payload;
          if (errors) {
            throw new Error(JSON.stringify(errors));
          }
          logs.push(data);
          logger.complete();
          return data?.[name as string] ?? undefined;
        }),
        catchError((error) => {
          if (error instanceof Error && error?.message) {
            try {
              error = JSON.parse(error.message);
            } catch (e) {}
          }
          this.debuggerService.log(error);
          return throwError(() => error);
        }),
      );
    } else if (isPlatformServer(this.platformId)) {
      return this.http
        .post(URL_ENDPOINTS.GRAPHQL, body, options)
        .pipe(
          map(
            ({ data, errors }: Record<string, any>) =>
              data?.[name as string] ?? undefined,
          ),
        );
    }

    const key: StateKey<string> = makeStateKey(JSON.stringify(body));
    const storedResponse: string = this.transferState.get<string>(key, null);
    if (storedResponse) {
      this.transferState.remove(key);
      return of(storedResponse);
    }

    return of(null);
  }

  public mutate<T>({
    operation,
    fields,
    variables,
  }: IQueryBuilderOptions): Observable<T> {
    return this.letsMaskRequest({
      ...mutation({ operation, fields, variables }, null, {
        operationName: operation,
      }),
      name: operation as string,
    });
  }

  public handleExtensions(name: string, keys: string[]) {
    return (errors): string[] => {
      const extensions = errors
        .filter(({ message }) => message === name)
        .map((a) => a.extensions);
      const extKeys = [].concat(...extensions.map((a) => a.keys));
      return keys.filter((key) => extKeys.includes(key));
    };
  }

  public getVariables(
    record: Record<string, any>,
    keyTypes: Record<
      string,
      | {
          type?: string;
          required?: boolean;
          value?: string;
        }
      | string
    >,
  ) {
    record = omitBy(record, isNil);
    const keyVariables = {};
    Object.keys(keyTypes).forEach((k) => {
      const typed = keyTypes[k];
      const value = typed.hasOwnProperty('value')
        ? (typed as { value: string }).value
        : record[k];
      const type =
        (typed as { type: string })?.type ?? typeof typed === 'string'
          ? typed
          : undefined;
      if (record.hasOwnProperty(k)) {
        keyVariables[k] = {
          ...(typeof typed === 'object' ? typed : {}),
          ...(typeof type === 'string' ? { type } : {}),
          value: value ?? undefined,
        };
      }
    });
    return keyVariables;
  }

  private callWorkerTask(task) {
    const worker = new WorkerTask(task);
    return new Observable((obs: any) => {
      worker.onmessage.pipe(filter((d) => d.taskId === task.id)).subscribe({
        next: (d: BackgroundTaskMessage) => {
          obs.next(d);
          if (d.taskStatus === 'TERMINATED') obs.complete();
        },
        error: (err) => {
          console.error(err);
          obs.error(err);
        },
      });
      timer(0)
        .pipe(take(1))
        .subscribe((d) => {
          worker.start();
        });
    });
  }

  private buildTask(url, body = {}, options = {}) {
    const filestream = uuidv4();
    const id = `TASK-${filestream}`;
    return {
      id,
      name: 'XHRHttpRequestWorker',
      jobs: [
        {
          id: `JOB-${filestream}`,
          method: 'post',
          arguments: [url, body, options],
        },
      ],
    };
  }
}
