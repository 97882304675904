import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export interface IGLOptions {
  name?: string;
  value?: string;
}

@Component({
  selector:
    'sa-select[formControlName], sa-select[formControl], sa-select[ngModel]',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnChanges {
  @HostBinding('class.disabled') public disabled = false;

  @Input() id: string = '';
  @Input() name: string = '';
  @Input() readonly: boolean = false;
  @Input() label: any = '';
  @Input() required: boolean = true;
  @Input() allowEmptyValue: boolean = false;
  @Input() options: IGLOptions[] | any[] = [];
  @Input() optionValueRef: ((c: unknown) => string) | string = 'value';
  @Input() optionNameRef: ((c: unknown) => string) | string = 'name';
  @Input() validators: ValidatorFn | ValidatorFn[] = [];

  public _model: string;
  public hasValue: boolean = false;
  public ctrl: AbstractControl;
  public selectOptions: (
    | { name: any; value: any }
    | {
        name: ((c: unknown) => string) | string;
        value: ((c: unknown) => string) | string;
      }
  )[] = [];
  public touched = false;
  public hasName = false;

  private defineSelectOptions() {
    /**
     * This is set to type of ANY, because we don't know the object
     * structure of the options to use,
     * The resulting output is going to be ICampSelectOptions
     * which the optionValueRef, and optionNameRef have to be set,
     * if the input is not type of ICampSelectOptions
     */
    this.selectOptions = (this.options ?? []).map((a: any) => {
      if (typeof a !== 'object') {
        return { name: a, value: a };
      }

      let nameRef = this.optionNameRef,
        valueRef = this.optionValueRef;

      if (typeof nameRef === 'function') {
        nameRef = nameRef(a);
      } else {
        nameRef = a[nameRef];
      }

      if (typeof valueRef === 'function') {
        valueRef = valueRef(a);
      } else {
        valueRef = a[valueRef];
      }

      const value = valueRef;
      const name = nameRef;
      return { name, value };
    });

    if (this.allowEmptyValue) {
      this.selectOptions.unshift({ name: null, value: null });
    }
  }

  set ngValue(e: string) {
    const isUndefined = e === '' || e === null || e === undefined;
    if (isUndefined) {
      this._model = null;
    } else {
      this._model = e;
    }

    const option = this.selectOptions.find((a) => a.value === this._model);
    this.hasName = !!option?.name;
  }

  private hasValueCheck() {
    let hasValue = !!this.selectOptions.filter((a) => a.value === this._model)
      .length;
    if (!hasValue && !this.readonly) {
      hasValue = true;
    }
    this.hasValue = hasValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.defineSelectOptions();
  }

  get ngValue() {
    return this._model;
  }

  /**
   * This is any, because it can be a string, number, boolean, or an object.
   */
  modelChanged(e: any) {
    this.ngValue = e;
    this.onChangeCallback(this.ngValue);
    this.onTouchedCallback(this.ngValue);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(ctrl: AbstractControl): ValidationErrors | null {
    this.ctrl = ctrl;
    const errors = <ValidationErrors>ctrl?.errors;
    this.required = errors?.required ?? false;
    return null;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(obj: string): void {
    if (obj !== this.ngValue) this.ngValue = obj || '';
  }

  private onTouchedCallback(e?: unknown) {}

  private onChangeCallback(e?: unknown) {}

  markAsTouched() {
    if (!this.touched) {
      this.onTouchedCallback();
      this.touched = true;
    }
  }
}
